
import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
    // {
    //     path: "/",
    //     name: "home",
    //     component: HomeView,
    // },
    {
        path: "/about",
        name: "about",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    },
    {
        path: "/server",
        name: "server",
        children: [
            {
                path: "jia",
                name: "jia",
                children: [
                    // 九月+隐私政策
                    {
                        path: 'privacy',
                        component: () => import(/* webpackChunkName: "server" */ "../views/JiaPrivacy.vue")
                    },
                    // 九月+服务协议
                    {
                        path: 'agreement',
                        component: () => import(/* webpackChunkName: "server" */ "../views/JiaAgreement.vue")
                    }
                ]
            },
            {
                path: 'shenghuo',
                name: 'shenghuo',
                children: [
                    // 九月生活隐私政策
                    {
                        path: 'privacy',
                        component: () => import(/* webpackChunkName: "server" */ "../views/shenghuoPrivacy.vue")
                    },
                    // 九月生活服务协议
                    {
                        path: 'agreement',
                        component: () => import(/* webpackChunkName: "server" */ "../views/shenghuoAgreement.vue")
                    }
                ]
            }
        ]
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

export default router;
